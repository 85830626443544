
const routes = [
    {
        path:"/quotations",
        name:"quotation_list",
        meta : {
            reqAuth: true,
            gate: 'view_quotation_list'
        },
        component: () => import("../views/Quotations.vue")
    },
    {
        path:"/quotation/details/:uuid",
        name:"quotation_details",
        meta : {
            reqAuth: true,
            gate: 'view_quotation_detail'
        },
        component: () => import("../views/QuotationDetails.vue")
    },
];

export default routes;