import { createApp } from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import { config } from "@/core/helpers/config";

import { Acl, Axios, Notify } from "./core/services";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";

import mitt from "mitt";
const emitter = mitt();

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import { QuillEditor } from "@vueup/vue-quill";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueEasyLightbox from "vue-easy-lightbox";

const app = createApp(App);

// app.config.performance = true;
app.use(Acl);
app.use(store);
app.use(ElementPlus);
app.use(VueGoogleAutocomplete);
initApexCharts(app);
initInlineSvg(app);

// Component Register
app.component("QuillEditor", QuillEditor);
app.component(VueEasyLightbox.name, VueEasyLightbox);

// Reason Store not ready
import "../src/modules/common";
import i18nProvider from "@/core/services/i18nProvider";
import axios from "axios";

app.use(i18n);
app.provide("instance", app);
app.provide("emitter", emitter);
localStorage.setItem('admin-theme-mode','light')
const theme = async () => {
	localStorage.getItem("admin-theme-mode") ? (config.value.aside.theme = localStorage.getItem("admin-theme-mode")) : localStorage.setItem("admin-theme-mode", config.value.aside.theme);
	// window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches
	// 	? (localStorage.setItem('admin-theme-mode','light'), config.value.aside.theme = 'light')
	// 	: (localStorage.setItem('admin-theme-mode','dark'), config.value.aside.theme = 'dark')
};

store.dispatch("system_integration");
const abilities = async () => {
	if (localStorage.getItem("token")) {
		try {
			const info = await store.dispatch("abilities", app);
			app.use(router);
			await theme();
			app.mount("#app");

			// if (info && info.data?.roles?.admin) {
			// 	app.use(router);
			// 	await theme();
			// 	app.mount("#app");
			// } else {
			// 	localStorage.removeItem("token");
			// 	if (info.data?.roles?.customer || info.data?.roles?.manager)
			// 	{
			// 		window.location.href = `${process.env.VUE_APP_GEP_SHOP}/auth/login`
			// 	}
			// }

		} catch (e) {
			app.use(router);
			Notify.error(e);
			await i18nProvider.SetLocaleMessage("en");
			theme();
			app.mount("#app");
		}
	} else {
		app.use(router);
		await i18nProvider.SetLocaleMessage("en");
		if (!localStorage.getItem("A_ID") || localStorage.getItem("A_ID") == undefined) {
			const resp = await Axios.post("/session/api-handshake", { locale: "en", session_id: localStorage.getItem("A_ID") });
			localStorage.setItem("A_ID", resp.data);
		}
		await theme();
		app.mount("#app");
	}
};

const authBeat = async (authInterval: any = null)=> {
		if (localStorage.getItem('state') || localStorage.getItem('code'))
		{
			await axios.post(`${process.env.VUE_APP_GEP_AUTH}/api/verify-auth`, {
				state: localStorage.getItem('state'),
				code: localStorage.getItem('code')
			}).then(data => {
				if (!data) {
					clearInterval(authInterval)
					store.dispatch("logout");
				}
			}).catch(e => {
				clearInterval(authInterval)
				store.dispatch("logout");
			});
		}
}

const authInterval = setInterval(() => {
	authBeat(authInterval);
},60000);

authBeat();
abilities();
