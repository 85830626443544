const routes = [
    {
        path: '/catalog/products',
        name: 'products',
        meta: {
            reqAuth: true,
            gate: 'view_products_list'
        },
        component: () => import('../products/views/Products.vue')
    },
    {
        path: '/catalog/product_groups',
        name: 'product_groups',
        meta: {
            reqAuth: true,
            gate: 'view_product_groups'
        },
        component: () => import('../product-groups/views/ProductGroups.vue')
    },
    {
        path: '/catalog/add-product-group',
        name: 'add-product-group',
        meta: {
            reqAuth: true,
            gate: 'add-product-group'
        },
        component: () => import('../product-groups/components/AddProductGroup.vue')
    },
    {
        path: '/catalog/add-product',
        name: 'add-product',
        meta: {
            reqAuth: true,
            gate: 'add_product'
        },
        component: () => import('../products/views/AddProduct.vue')
    },
    {
        path: '/catalog/edit-product/:uuid',
        name: 'edit-product',
        meta: {
            reqAuth: true,
            gate: 'edit_product'
        },
        component: () => import('../products/views/EditProduct.vue')
    },
    {
        path: '/catalog/categories',
        name: 'categories',
        meta: {
            reqAuth: true,
            gate: 'view_category_list'
        },
        component: () => import('../categories/views/Categories.vue')
    },
    {
        path: '/catalog/manufacturers',
        name: 'manufacturers',
        meta: {
            reqAuth: true,
            gate: 'view_manufacturers_list'
        },
        component: () => import('../manufacturers/views/Manufacturers.vue')
    },
    {
        path: '/catalog/attributes',
        name: 'attributes',
        meta: {
            reqAuth: true,
            gate: 'view_attributes_list'
        },
        component: () => import('../attributes/views/Attributes.vue')
    },
    {
        path: '/catalog/dimensions',
        name: 'dimensions',
        meta: {
            reqAuth: true,
            gate: 'view_dimensions_list'
        },
        component: () => import('../dimensions/views/Dimensions.vue')
    },
    {
        path: '/category/products-sorting/:slug',
        name: 'categor_products_sorting',
        meta: {
            reqAuth: true,
            gate: 'view_category_products_list'
        },
        component: () => import('../products/views/SortCategoryProducts.vue')
    },
];

export default routes;
