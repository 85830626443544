import { createI18n } from "vue-i18n";

const messages = {
  en: { },
  da: { }
};

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  messages
});

export default i18n;
