const routes = [
	{
		path: "/sap",
		name: "sap",
		meta: {
			reqAuth: true,
			gate: 'view_economic'
		},
		component: () => import("../views/Sap.vue")
	},
]

export default routes